import { render, staticRenderFns } from "./questionBefore.vue?vue&type=template&id=6eae85bb&scoped=true&"
import script from "./questionBefore.vue?vue&type=script&lang=js&"
export * from "./questionBefore.vue?vue&type=script&lang=js&"
import style0 from "./questionBefore.vue?vue&type=style&index=0&id=6eae85bb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eae85bb",
  null
  
)

export default component.exports