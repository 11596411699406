<template>
  <div class="question">
    <img src="@assets/images/questionTr.jpg" />
    <div class="container">
      <form>
        <div class="tips">各位学员：</div>
        <p>感谢您参与本次密训，耽误您几分钟时间请您协助完成此份调查问卷，您的评价对于我们改进培训工作来说非常重要。请在相对应的括号内打“√”。谢谢您的配合！</p>

        <div class="box">
          <span>课程名称：</span>
          <van-dropdown-menu title="请选择课程名称">
            <van-dropdown-item
              v-model="lesson_id"
              :options="lessonData"
              @change="sendBefore('query')"
            />
          </van-dropdown-menu>
        </div>
        <div class="box">
          <span>主讲老师：</span>
          <input
            type="text"
            maxlength="20"
            v-model="teacher"
            placeholder="请输入主讲老师名称"
          />
        </div>

        <div class="radio-title">1、基本情况（技术年限）？</div>
        <van-radio-group v-model="form.a1">
          <van-radio icon-size=".32rem" name="无基础">无基础</van-radio>
          <van-radio icon-size=".32rem" name="0-半年基础">0-半年基础</van-radio>
          <van-radio icon-size=".32rem" name="有半年左右基础">有半年左右基础</van-radio>
          <van-radio icon-size=".32rem" name="有1年以上基础">有1年以上基础</van-radio>
          <van-radio icon-size=".32rem" name="3年以上纹绣师">3年以上纹绣师</van-radio>
        </van-radio-group>

        <div class="radio-title">2、是否有美容店？</div>
        <van-radio-group v-model="form.a2">
          <van-radio icon-size=".32rem" name="从未开过美容店">从未开过美容店</van-radio>
          <van-radio icon-size=".32rem" name="曾经开过美容店">曾经开过美容店</van-radio>
          <van-radio icon-size=".32rem" name="目前正在开店">目前正在开店</van-radio>
          <van-radio icon-size=".32rem" name="计划未来开店">计划未来开店</van-radio>
        </van-radio-group>

        <div class="radio-title">3、是否有过真人实操经验？</div>
        <van-radio-group v-model="form.a3">
          <van-radio icon-size=".32rem" name="无操作真人经验">无操作真人经验</van-radio>
          <van-radio icon-size=".32rem" name="1-3个真人操作经验">1-3个真人操作经验</van-radio>
          <van-radio icon-size=".32rem" name="3-20个真人操作经验">3-20个真人操作经验</van-radio>
          <van-radio icon-size=".32rem" name="20个以上真人操作经验">20个以上真人操作经验</van-radio>
        </van-radio-group>

        <div class="radio-title">4、本次学习最想解决什么？</div>
        <van-radio-group v-model="form.a4" v-for="val in beforeData.children1">
          <van-radio icon-size=".32rem" :name="val" v-if="val!='other'">{{val}}</van-radio>
          <van-radio icon-size=".32rem" name="other" v-if="val=='other'">其他</van-radio>
          <input v-if="val=='other'" type="text" maxlength="50" v-model="a4_o" placeholder="其他..."/>
        </van-radio-group>

        <div class="radio-title">5、是否愿意参加实操班？</div>
        <van-radio-group v-model="form.a5">
          <van-radio icon-size=".32rem" name="愿意">愿意</van-radio>
          <van-radio icon-size=".32rem" name="不愿意">不愿意</van-radio>
        </van-radio-group>

        <div class="radio-title" v-if="beforeData.children2.length>0">6、参加实操班你想解决什么问题？</div>
        <van-radio-group v-model="form.a6" v-for="val in beforeData.children2">
            <van-radio icon-size=".32rem" :name="val" v-if="val!='other'">{{val}}</van-radio>
            <van-radio icon-size=".32rem" name="other" v-if="val=='other'">其他</van-radio>
            <input v-if="val=='other'" type="text" maxlength="50" v-model="a6_o" placeholder="其他..."/>
        </van-radio-group>

        <div class="radio-title">{{num+1}}、总部密训情况？</div>
        <van-radio-group v-model="form.a7">
          <van-radio icon-size=".32rem" name="首次学员">首次学员</van-radio>
          <van-radio icon-size=".32rem" name="复训学员">复训学员</van-radio>
          <van-radio icon-size=".32rem" name="满1年的老学员">满1年的老学员</van-radio>
          <van-radio icon-size=".32rem" name="多年的老学员">多年的老学员</van-radio>
        </van-radio-group>

        <div class="radio-title">{{num+2}}、子公司密训情况？</div>
        <van-radio-group v-model="form.a8">
          <van-radio icon-size=".32rem" name="从未参加过子公司密训">从未参加过子公司密训</van-radio>
          <van-radio icon-size=".32rem" name="在子公司密训过1次">在子公司密训过1次</van-radio>
          <van-radio icon-size=".32rem" name="参加过子公司的传承班">参加过子公司的传承班</van-radio>
          <van-radio icon-size=".32rem" name="参加过子公司的实操班">参加过子公司的实操班</van-radio>
        </van-radio-group>

        <div class="radio-title">{{num+3}}、未来就业方向？</div>
        <van-radio-group v-model="form.a9">
          <van-radio icon-size=".32rem" name="打算自主创业开店">打算自主创业开店</van-radio>
          <van-radio icon-size=".32rem" name="成为公司纹绣老师">成为公司纹绣老师</van-radio>
          <van-radio icon-size=".32rem" name="成为公司主讲老师">成为公司主讲老师</van-radio>
          <van-radio icon-size=".32rem" v-if="before_id==1011" name="纹绣兼职">纹绣兼职</van-radio>
          <van-radio icon-size=".32rem" v-if="before_id==1011" name="后期找这方面的工作">后期找这方面的工作</van-radio>
          <van-radio icon-size=".32rem" v-if="before_id!=1011" name="自己成立培训机构">自己成立培训机构</van-radio>
        </van-radio-group>

        <div class="radio-title">{{num+4}}、写下您本次来学习的期望吧？</div>
        <textarea v-model="a10"></textarea>

        <div class="submit" @click="submitQuestion()">提交问卷</div>
        <h4>
          再次感谢您的配合！
          <br />期待与您相会在聚亿美大家庭！
        </h4>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, Radio, DropdownMenu, DropdownItem } from "vant";
import { questionLessonPublic, questionnaireBefore } from "@api/user";
// 引入防抖和节流函数
import globalFunction from "@utils/globalFunction";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
export default {
  data() {
    return {
      id: 0,
      lesson_id: "",
      teacher: "",
      num:6,
      before_id:1001,
      lessonData: [],
      lessonList:[],
      beforeData:[],
      beforeList:[],
      form: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        a9: ""
      },
      a10: "",
      a6_o:'',
      a4_o:'',
      flag: true,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getLesson();
  },
  methods: {
    // 获取课程
    getLesson() {
      questionLessonPublic(2).then((res) => {
        const data = res.data.lessonList;
        this.beforeList = res.data.beforeList;
        this.lessonList = data;
        const array = [];
        for (const key in data) {
          if (parseInt(key) == parseInt(this.id)) {
            this.beforeData = this.beforeList[data[key].before_id];
            this.before_id = data[key].before_id;
            array.unshift({
              value: key,
              text: data[key].bookname,
            });
          } else {
            array.push({
              value: key,
              text: data[key].bookname,
            });
            if(this.beforeData.length == 0){
                this.beforeData = this.beforeList[1001];
            }
          }
        }
        if(this.beforeData.children2.length>0){this.num = 6;}else{this.num = 5;}
        let boo = array.some((item) => item.value == this.id);
        if (!boo) array.unshift({ value: 0, text: "请选择课程" });
        this.lessonData = array;
        this.lesson_id = array[0].value;
      });
    },
    // 防抖函数
    // debounce(fn, wait) {
    //   return (res) => {
    //     if (this.timeout !== null) {
    //       clearTimeout(this.timeout);
    //     }
    //     this.timeout = setTimeout(() => fn(res), wait);
    //   };
    // },
    // 问卷题型修改
    sendBefore() {
        if (this.lesson_id == 0){
            this.beforeData = this.beforeList[1001];
        }else{
            let beforeid = this.lessonList[this.lesson_id].before_id;
            this.before_id = beforeid;
            this.beforeData = this.beforeList[beforeid];
        }
        if(this.beforeData.children2.length>0){this.num = 6;}else{this.num = 5;}
    },
    // 请求提交接口
    sendAjax() {
      let params = {};
      let form = this.form;
      // 必填提示
    if (!this.flag) {
      this.$dialog.error("您已经提交过了哦");
      return;
    }
    if (!this.lesson_id) {
      this.$dialog.error("课程名称必选择");
      return;
    }
    if (!this.teacher) {
      this.$dialog.error("主讲老师必填");
      return;
    }
    for (const key in form) {
      if (!form[key] && key != 'a6') {
        this.$dialog.error("亲，还有问卷未选择哦！");
        return;
      }

      if(form[key] == 'other'){
          if(key == 'a4' && !this.a4_o){
              this.$dialog.error("亲，本次学习最想解决什么 其他原因未填写");
              return;
          }
          if(key == 'a6' && !this.a6_o){
              this.$dialog.error("亲，参加实操班你想解决什么问题 其他原因未填写");
              return;
          }

      }
    }

        params = {
          lesson_id: this.lesson_id,
          teacher: this.teacher,
          ...this.form,
          a4_o:this.a4_o,
          a6_o:this.a6_o,
          a10:this.a10
        };


      questionnaireBefore(params)
        .then(() => {
            this.flag = false;
          this.$dialog.success("提交成功！");
            setTimeout(() => {
              this.$router.back();
          }, 1500);
        })
        .catch((err) => {
          this.flag = true;
          this.$dialog.error(err.msg);
        });
    },
    // 提交
    submitQuestion:globalFunction.debounce(function(){
        this.sendAjax()
    },2000),
  },
};
</script>

<style scoped lang="scss">
.question {
  padding: 0.2rem;
  background: linear-gradient(#fff, #354af5);
  color: black;
  img {
    width: 100%;
    height: auto;
  }
  .container {
    background: white;
    font-size: 0.24rem;
    padding: 0.2rem;
    .box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 0.6rem;
      line-height: 0.6rem;
      span {
        width: 20%;
      }
      input {
        width: 78%;
        text-align: right;
        border-bottom: 1px solid #000;
      }
    }
    .tips {
      color: #354af5;
      border-bottom: 1px dashed #354af5;
      font-size: 0.32rem;
      line-height: 0.72rem;
    }
    p {
      text-indent: 2em;
      line-height: 0.4rem;
      margin: 0.2rem 0 0.3rem 0;
    }
    .van-radio-group {
      display: flex;
      flex-flow: row nowrap;
      flex-wrap: wrap;
      input[type="text"] {
        margin-left: 0.2rem;
        width: 2.6rem;
        border-bottom: 1px solid #666;
      }
    }
    .title {
      font-weight: 700;
      font-size: 0.26rem;
      height: 0.5rem;
      line-height: 0.5rem;
      padding-left: 0.2rem;
      background: #f8f8f8;
      margin: 0.3rem 0;
    }
    .radio-title {
      margin: 0.3rem 0 0.15rem 0;
    }
    textarea {
      border: 1px solid #000;
      border-radius: 0.12rem;
      width: 100%;
      height: 2rem;
      padding: 0.2rem;
    }
    .submit {
      display: block;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0;
      font-size: 0.32rem;
      background: #3e3efd;
      text-align: center;
      border-radius: 0.12rem;
      color: white;
    }
    h4 {
      margin: 0.2rem 0;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 500;
    }
  }
  .van-dropdown-menu {
    width: 78%;
    height: 0.6rem;
    border: none;
    border-bottom: 1px solid #000;
    padding-right: 8px;
  }
}
.question .van-radio__label {line-height: 26px;}
</style>